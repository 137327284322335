import * as Sentry from "@sentry/remix";
import { HydratedRouter } from "react-router/dom";
import { useLocation, useMatches } from "react-router";
import { createInstance } from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { getInitialNamespaces } from "remix-i18next/client";

import { hydrateRoot } from "react-dom/client";
import React, { startTransition, StrictMode, useEffect } from "react";

import en from "~/locales/en";
import nl from "~/locales/nl";
import { DialogWindow } from "~/utils/api.interfaces.enums";

declare let window: DialogWindow;
Sentry.init({
  dsn: "https://e8956798c804a8bea8484a4140d8f45f@o4508252318007296.ingest.de.sentry.io/4508257486569552",
  tracesSampleRate: 1,
  environment: window.ENV.ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});

const GTM_ID = "GTM-MLWHPPSF";

let gtmScriptAdded = false;

/**
 * Adds Google Tag Manager scripts to the page
 * Only runs once and skips execution in local environment
 */
function addGtmScript() {
  // Skip if script already added or if in local environment
  if (gtmScriptAdded || window.ENV.ENVIRONMENT === "local") {
    return;
  }

  // Add GTM script to head
  const addGtmToHead = () => {
    // Standard GTM implementation
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });

    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`;

    const firstScript = document.getElementsByTagName("script")[0];
    if (firstScript?.parentNode) {
      firstScript.parentNode.insertBefore(script, firstScript);
    }
  };

  // Add GTM noscript iframe to body for browsers with JavaScript disabled
  const addGtmNoScript = () => {
    const noscript = document.createElement("noscript");
    const iframe = document.createElement("iframe");

    iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";

    noscript.appendChild(iframe);
    document.body.insertBefore(noscript, document.body.firstChild);
  };

  // Execute both parts
  addGtmToHead();
  addGtmNoScript();

  // Mark as complete
  gtmScriptAdded = true;
}

function App() {
  useEffect(() => {
    addGtmScript();
  }, []);

  return <HydratedRouter />;
}
async function hydrate() {
  let instance = createInstance()
    .use(initReactI18next) // Tell i18next to use the react-i18next plugin
    .use(LanguageDetector); // Setup a client-side language detector
  // .use(Backend); // Setup your backend
  await instance.init({
    supportedLngs: ["nl", "en"],
    fallbackLng: "en",
    react: { useSuspense: false },
    ns: getInitialNamespaces(),
    detection: { order: ["htmlTag"], caches: [] },
    resources: { en: { translation: en }, nl: { translation: nl } },
    interpolation: { escapeValue: false },
  });

  startTransition(() => {
    // const [theme] = useTheme();
    hydrateRoot(
      document,

      <StrictMode>
        <I18nextProvider i18n={instance}>
          <App />
        </I18nextProvider>
      </StrictMode>
    );
  });
}
if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
